import { ThemeObject } from '../interface';
import { statics } from './StaticsComponents';

export const inceptia: ThemeObject = {
  color: {
    navBar: '#303041',
    navBarIcon: '#E7EBEF',
    navBarTxt: '#37303E',
    sideBar: '#E7EBEF',
    txt: '#37303E',
    txtDark: '#37303E',
    txtLigth: '#37303e99',
    bkg: '#f4f6f8',
    bkgLight: '#FAF9F9',
    btnPrimary: '#1976d2',
    btnTextPrimary: '#ffff',
    btnSecondary: '#7e9ab7',
    error: '#d32f2f',
    btnGreen: '#19a119',
    border: '#0000001f',
    white: '#ffff',
    item: '#45526E',
    itemSelectedText: '#1976d2',
    itemSelected: '#1a73e824',
    link: '#1976d2',
    linkHover: '#c6d3e061'
  },
  ...statics
};
