import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { UploadModel } from "src/pages/Dashboard/Bots/CreateCampaign/models";
import { postTasksService } from "src/services/botsServices/campaign";
import { getTasksService } from "src/services/botsServices/campaign/getTasks";
import { putTasksService } from "src/services/botsServices/campaign/putTasks";

const errorMsg =
  'The file could not be processed correctly, please try again. If the problem persists, contact technical support';

const isTaskValid = (status: string) => {
  return status === 'VALIDATED' || status === 'RECEIVED_WITH_ERRORS';
}

const pollingTasks = async (resp:AxiosResponse,signal:AbortSignal ) => {

  let pollingInterval = 2000; // Init  2 seconds
  const maxPollingInterval = 30000; // Max 30 seconds  
  const {id,status} = resp.data

  const getTasks:any = async() => {
  
    try {
      const response = await getTasksService({id,signal}); 
      const {status:getStatus} = response.data;
      if (isTaskValid(getStatus)) return response;
      pollingInterval = Math.min(pollingInterval * 2, maxPollingInterval);
      await new Promise(resolve => setTimeout(resolve, pollingInterval));
      return getTasks();
    } catch (error) {
      throw error; 
    }
    
  };  

  if (isTaskValid(status)) {
    return resp;
  }

  try {
    const response = await getTasks();
    return response;
  } catch (error) {
    throw error;
  }
};

interface PostTasksProps {
  formData:FormData
  formParams:UploadModel,
  signal:AbortSignal
}

export const post_tasks = createAsyncThunk(
  'tasks/post_tasks',
  async({formData,formParams,signal}:PostTasksProps, {rejectWithValue}) => {
    try {      
      const resp:any = await postTasksService({formData,signal});     
      const response = await pollingTasks(resp,signal)
      return {response,formParams};
    } catch (error:any) {
      throw rejectWithValue(error.response);
    }
});

interface PutTasksProps {
  formData:FormData
  formParams:UploadModel
  id:string,
  signal:AbortSignal
}

export const put_tasks = createAsyncThunk(
  'tasks/put_tasks',
  async({formData,formParams,id,signal}:PutTasksProps, {rejectWithValue}) => {
    try {      
      const resp:any = await putTasksService({formData,id,signal});
      const response:any = await pollingTasks(resp,signal)
      return {response,formParams};
    } catch (error:any) {
      throw rejectWithValue(error.response);      
    }
});

interface Props {
  response: any,
  status: any,
  error: any,
  preview:boolean,
  formParams:any,
  id:string,
  action:string,
};

const initialState: Props = 
  {
    response: {},
    status: '',
    error: null,
    preview:false,
    formParams:null,
    id:'',
    action:''

  }
   
export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
   reducers: {
    setPreview: (state, action) => {
      state.preview = action.payload;
      state.error = null;
      state.status ='';
      state.response = {};
      state.action = 'modify'
    },
    setClean: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(post_tasks.pending, (state) =>{
      state.status = 'loading';
    })
    builder.addCase(post_tasks.fulfilled, (state,{payload}: PayloadAction<any>) =>{
      state.response = payload.response.data;
      state.status = payload.response.status;
      state.error = null; 
      state.preview = true;
      state.formParams = payload.formParams  
      state.action = 'process'
      state.id = payload.response.data.id
    })
    builder.addCase(post_tasks.rejected, (state,{payload}:any) =>{
      state.response = {};
      state.status = payload?.status || 404;
      state.error = payload?.data || errorMsg;
      state.preview = true; 
    })  

    builder.addCase(put_tasks.pending, (state) =>{
      state.status = 'loading';
    })
    builder.addCase(put_tasks.fulfilled, (state,{payload}: PayloadAction<any>) =>{
      state.response = payload.response.data;
      state.status = payload.response.status;
      state.error = null; 
      state.preview = true;
      state.formParams = payload.formParams  
      state.action = 'process'
      state.id = payload.response.data.id
    })
    builder.addCase(put_tasks.rejected, (state,{payload}:any) =>{
      state.response = {};
      state.status = payload?.status || 404;
      state.error = payload?.data || errorMsg;
      state.preview = true; 
    })  

 
  }
});
export const { setPreview,setClean } = tasksSlice.actions;
export default tasksSlice.reducer;